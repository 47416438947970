import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl } from '../util/constants';

const token = localStorage.getItem('tkn-access');

//const baseUrl = 'https://multisolucoes.grupomultiplica.com.br';
// const baseUrl = 'https://2xcapital.grupomultiplica.com.br';
//const baseUrl = 'https://multipague-hml.grupomultiplica.com.br';

export const api_multipague_conta = axios.create({
  baseURL: baseUrl + '/ms-conta/v1',
  headers: {
    ContentType: 'application/json',
  },
});

export const api_multipague_transacao = axios.create({
  baseURL: baseUrl + '/ms-transacao/v1',
  headers: {
    // Authorization: `Bearer ${token}`,
    IdempotencyKey: uuidv4(),
  },
});

export const api_multipague_agenda_financeira = axios.create({
  baseURL: baseUrl + '/ms-agenda-financeira/v1',
  headers: {
    // Authorization: `Bearer ${token}`,
  },
});

export const api_multipague_cobranca = axios.create({
  baseURL: baseUrl + '/ms-cobranca/v1',
  headers: {
    // Authorization: `Bearer ${token}`,
    IdempotencyKey: uuidv4(),
    'X-ASSINATURA-FINANCEIRA': '81dc9bdb52d04dc20036dbd8313ed055',
  },
});

export const api_multipague_configuracao = axios.create({
  baseURL: baseUrl + '/ms-configuracao/v1',
});

export const api_multipague_despesa = axios.create({
  baseURL: baseUrl + '/ms-despesa/v1',
  headers: {
    // Authorization: `Bearer ${token}`,
  },
});

export const api_multipague_esteira = axios.create({
  baseURL: baseUrl + '/ms-esteira/v1',
  headers: {
    // Authorization: `Bearer ${token}`,
  },
});

export const api_multipague_cep = axios.create({
  baseURL: baseUrl + '/mc-cep/v1',
});
