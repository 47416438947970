import React, { useState } from 'react'

import { ArrowForwardIcon, ArrowBackIcon, AddIcon } from '@chakra-ui/icons'
import {
  useBreakpointValue,
  Heading,
  Stack,
  Flex,
  SimpleGrid,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Select,
  Avatar,
  Button,
  useColorModeValue
} from '@chakra-ui/react'
import { useForm } from '../../contexts/FormContext'

export default function Pagina4() {

  const bg = useColorModeValue('#fff', 'gray.800')
  const topNumer = useColorModeValue('#fff', 'gray.800')

    const { pageThree, setPageThree } = useForm()
    const { pageFour, setPageFour } = useForm()
    const { pageFive, setPageFive } = useForm()
    const { newCorrentista, setNewCorrentista } = useForm()
    const { checkTipoPessoa, setCheckTipoPessoa } = useForm();

    const [select, setSelect] = useState("")

    const handleChangeConta = event => {
        setSelect(event.target.value);
    };

    function isNewCorrentista() {
        setNewCorrentista(true)
    }

    function pageFourToPageThree() {
        setPageThree(true)
        setPageFour(false)
    }

    function pageFourToPageFive() {
        setPageFour(false)
        setPageFive(true)
    }

    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true
      })

    return (
        <>
        {isWideVersion && (
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >
              <Box display="flex">
                <Stack direction='row'>
                  <Avatar name='1' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Heading as='h4' size='sm' my="2" ml="2">
                  {checkTipoPessoa ? 'Dados da pessoa jurídica' : 'Dados da pessoa física'}
                </Heading>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='2' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de contato
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='3' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de endereço
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='4' size='sm' bg='#65c4d5' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Minha conta
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='5' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Termo
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='6' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Senha
                </Text>
              </Box>
    
            </Flex>
          )}
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >        
              <Box alignItems='center'>
                <Heading as='h4' size='md' mb="0">
                    Minha conta
                </Heading>  
              </Box>
            </Flex>
            <Flex direction="column">          
    
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" border='1px' borderColor='gray.200' bg={bg} borderRadius={8}>            
    
                    <FormControl>
                      
                        <SimpleGrid columns={isWideVersion ? 3 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Selecione uma conta</FormLabel>
                                <Select placeholder='274 - Moneyplus' disabled borderColor='gray.300' onChange={handleChangeConta} value={select}>
                                    <option value='274' >274 - Moneyplus</option>
                                </Select>
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Agência</FormLabel>
                                <Input 
                                    id='agencia'
                                    type='text'
                                    borderColor='gray.300'
                                    value="0001"
                                    // onChange={(e) => setPage4Agencia(e.target.value)} 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Tipo da conta</FormLabel>
                                <Input 
                                    id='tipoConta'
                                    type='text'
                                    borderColor='gray.300'
                                    value="3 - CONTA PAGAMENTO"
                                    // onChange={(e) => setPage4Conta(e.target.value)} 
                                />
                              </Box>  
                        </SimpleGrid>                        
                    </FormControl>                    
                </Flex> 
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6"  justifyContent="flex-end">
                        <Box>
                            <Button leftIcon={<ArrowBackIcon />} mr="5" colorScheme='teal' variant='outline' onClick={() => pageFourToPageThree()}>
                            Anterior
                            </Button>
                            <Button rightIcon={<ArrowForwardIcon />} colorScheme='teal' onClick={() => pageFourToPageFive()}>
                            Próximo
                            </Button>
                        </Box>
                    </Flex>   
            </Flex>
        </>    
    )
}