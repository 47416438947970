/* eslint-disable */
import React from 'react';
import './index.css';
import Routes from './routes/index';
import { FormProvider } from "./contexts/FormContext"
import { ptBR } from '@material-ui/core/locale/';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { theme } from './styles/theme';
import { generateApiApp, generateThemeDefault } from './styles/api.theme';
import { ThemeProvider, createTheme } from '@material-ui/core';


export default function Index() {

  const [themeApp, setThemeApp] = React.useState(null);
  const clearStorage = React.useCallback(() => {
    generateThemeDefault();
  }, []);


  React.useEffect(() => {
    clearStorage();
    generateApiApp()
      .then(theme => {
        theme.palette.type = 'light';
        const customTheme = createTheme({ ...theme }, ptBR);
        setThemeApp(customTheme);
      })
      .catch(erro => console.log('main erro pegar tehema ', erro));
  }, []);

  return (
    <>
      <ColorModeScript initialColorMode={theme.initialColorMode} />
      <FormProvider>
        <ThemeProvider theme={themeApp}>
          <ChakraProvider theme={theme}>
            <Routes />
          </ChakraProvider>
        </ThemeProvider>
      </FormProvider>
    </>
  )
}
