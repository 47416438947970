import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Register from '../pages/index';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/new-register" exact component={Register} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
