import React from 'react';

import InputMask from 'react-input-mask';
import { ArrowForwardIcon, ArrowBackIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  useBreakpointValue,
  Heading,
  Stack,
  Flex,
  SimpleGrid,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Avatar,
  Button,
  InputGroup,
  InputLeftElement,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from '../../contexts/FormContext';
import paises from '../../util/paises.json';

export default function Pagina1() {
  const bg = useColorModeValue('#fff', 'gray.800');
  const topNumer = useColorModeValue('#fff', 'gray.800');

  const {
    setPageOne,
    setPageTwo,
    setPageThree,
    checkTipoPessoa,
    page1RepresentanteNome,
    page2RepresentanteEmail,
    setPage2RepresentanteEmail,
    page2RepresentanteRedeSocial,
    setPage2RepresentanteRedeSocial,
    page2RepresentanteDDD,
    setPage2RepresentanteDDD,
    page2RepresentanteDDI,
    setPage2RepresentanteDDI,
    page2RepresentanteDDI2,
    setPage2RepresentanteDDI2,
    page2RepresentanteDDI3,
    setPage2RepresentanteDDI3,
    newCorrentista,
    setNewCorrentista,
  } = useForm();
  const { page2RepresentanteDDD2, setPage2RepresentanteDDD2 } = useForm();
  const { page2RepresentanteDDD3, setPage2RepresentanteDDD3 } = useForm();
  const { page2RepresentanteTelefone, setPage2RepresentanteTelefone } =
    useForm();
  const { page2RepresentanteTelefone2, setPage2RepresentanteTelefone2 } =
    useForm();
  const { page2RepresentanteTelefone3, setPage2RepresentanteTelefone3 } =
    useForm();

  function pageTwoToPageOne() {
    setPageOne(true);
    setPageTwo(false);
  }

  function pageTwoToPageThree() {
    setPageThree(true);
    setPageTwo(false);
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const handleChange = (index, e, name) => {
    setNewCorrentista(prev =>
      prev.map((correntista, i) =>
        i === index ? { ...correntista, [name]: e } : correntista,
      ),
    );
  };

  return (
    <>
      {isWideVersion && (
        <Flex
          w="100%"
          my="6"
          maxWidth={1120}
          mx="auto"
          px="6"
          justifyContent="space-between"
        >
          <Box display="flex">
            <Stack direction="row">
              <Avatar name="1" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Heading as="h4" size="sm" my="2" ml="2">
              {checkTipoPessoa
                ? 'Dados da pessoa jurídica'
                : 'Dados da pessoa física'}
            </Heading>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="2" size="sm" bg="#65c4d5" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Dados de contato
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="3" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Dados de endereço
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="4" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Minha conta
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="5" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Termo
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="6" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Senha
            </Text>
          </Box>
        </Flex>
      )}
      <Flex
        w="100%"
        my="6"
        maxWidth={1120}
        mx="auto"
        px="6"
        justifyContent="space-between"
      >
        <Box alignItems="center">
          <Heading as="h4" size="md" mb="0">
            Dados de contato do representante da empresa
          </Heading>
        </Box>
      </Flex>
      <Flex direction="column">
        <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6">
          <FormControl>
            <SimpleGrid
              columns={isWideVersion ? 3 : 1}
              spacing={2}
              bg={bg}
              border="1px"
              borderColor="gray.200"
              borderRadius={8}
              mb={'2'}
            >
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="cnpj">Nome</FormLabel>
                <Input
                  id="nome"
                  borderColor="gray.300"
                  value={page1RepresentanteNome}
                  disabled
                />
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="razaoSocial">Email</FormLabel>
                <Input
                  id="razaoSocial"
                  type="email"
                  borderColor="gray.300"
                  value={page2RepresentanteEmail}
                  onChange={e => setPage2RepresentanteEmail(e?.target?.value)}
                />
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="razaoSocial">Rede Social</FormLabel>
                <Input
                  id="razaoSocial"
                  type="text"
                  borderColor="gray.300"
                  value={page2RepresentanteRedeSocial}
                  onChange={e =>
                    setPage2RepresentanteRedeSocial(e?.target?.value)
                  }
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid
              columns={isWideVersion ? 4 : 1}
              spacing={2}
              bg={bg}
              pb={2}
              border="1px"
              borderColor="gray.200"
              borderRadius={8}
              mb={'2'}
            >
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="ddd">DDI</FormLabel>
                <Select
                  placeholder="Selecione uma opção"
                  size="md"
                  variant="outline"
                  value={page2RepresentanteDDI}
                  onChange={e => {
                    setPage2RepresentanteDDI(e.target?.value);
                  }}
                >
                  {Object.values(paises)?.map((item, index) => (
                    <option key={index} value={item?.ddi}>
                      {`${item.pais} (+${item.ddi})`}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="ddd">DDD</FormLabel>
                <Input
                  as={InputMask}
                  maskChar={null}
                  id="ddd"
                  type="text"
                  borderColor="gray.300"
                  mask="99"
                  value={page2RepresentanteDDD}
                  onChange={e => setPage2RepresentanteDDD(e?.target?.value)}
                />
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="telefone">Telefone</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<PhoneIcon color="gray.300" />}
                  />
                  <Input
                    as={InputMask}
                    maskChar={null}
                    id="telefone"
                    type="tel"
                    borderColor="gray.300"
                    mask="999999999"
                    value={page2RepresentanteTelefone}
                    onChange={e =>
                      setPage2RepresentanteTelefone(e?.target?.value)
                    }
                  />
                </InputGroup>
              </Box>
              <br />
              <Box
                colSpan={isWideVersion ? 3 : 1}
                p={['3', '3']}
                borderRadius={8}
                pb="6"
              >
                <FormLabel htmlFor="ddd">
                  DDI do telefone 2 (Opcional)
                </FormLabel>
                <Select
                  placeholder="Selecione uma opção"
                  size="md"
                  variant="outline"
                  value={page2RepresentanteDDI2}
                  onChange={e => {
                    setPage2RepresentanteDDI2(e.target?.value);
                  }}
                >
                  {Object.values(paises)?.map((item, index) => (
                    <option value={item?.ddi}>
                      {`${item.pais} (+${item.ddi})`}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="ddd2">
                  DDD do telefone 2 (Opcional)
                </FormLabel>
                <Input
                  as={InputMask}
                  maskChar={null}
                  id="ddd2"
                  type="text"
                  borderColor="gray.300"
                  mask="99"
                  value={page2RepresentanteDDD2}
                  onChange={e => setPage2RepresentanteDDD2(e?.target?.value)}
                />
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="telefone2">Telefone 2 (opcional)</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<PhoneIcon color="gray.300" />}
                  />
                  <Input
                    as={InputMask}
                    maskChar={null}
                    id="telefone2"
                    type="text"
                    borderColor="gray.300"
                    mask="999999999"
                    value={page2RepresentanteTelefone2}
                    onChange={e =>
                      setPage2RepresentanteTelefone2(e?.target?.value)
                    }
                  />
                </InputGroup>
              </Box>
            </SimpleGrid>
            <SimpleGrid
              columns={isWideVersion ? 4 : 1}
              spacing={2}
              bg={bg}
              p={2}
              border="1px"
              borderColor="gray.200"
              borderRadius={8}
              mb={'2'}
            >
              <Box
                colSpan={isWideVersion ? 3 : 1}
                p={['3', '3']}
                borderRadius={8}
                pb="6"
              >
                <FormLabel htmlFor="ddd">
                  DDI do telefone 3 (Opcional)
                </FormLabel>
                <Select
                  placeholder="Selecione uma opção"
                  size="md"
                  variant="outline"
                  value={page2RepresentanteDDI3}
                  onChange={e => {
                    setPage2RepresentanteDDI3(e.target?.value);
                  }}
                >
                  {Object.values(paises)?.map((item, index) => (
                    <option value={item?.ddi}>
                      {`${item.pais} (+${item.ddi})`}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="ddd3">
                  DDD do telefone 3 (opcional)
                </FormLabel>
                <InputGroup>
                  <Input
                    as={InputMask}
                    maskChar={null}
                    id="ddd3"
                    type="text"
                    borderColor="gray.300"
                    mask="99"
                    value={page2RepresentanteDDD3}
                    onChange={e => setPage2RepresentanteDDD3(e?.target?.value)}
                  />
                </InputGroup>
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="telefone3">Telefone 3 (opcional)</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<PhoneIcon color="gray.300" />}
                  />
                  <Input
                    as={InputMask}
                    maskChar={null}
                    id="telefone3"
                    type="text"
                    borderColor="gray.300"
                    mask="999999999"
                    value={page2RepresentanteTelefone3}
                    onChange={e =>
                      setPage2RepresentanteTelefone3(e?.target?.value)
                    }
                  />
                </InputGroup>
              </Box>
            </SimpleGrid>
          </FormControl>
        </Flex>
        {newCorrentista.length > 0 && (
          <>
            <Flex
              w="100%"
              my="6"
              maxWidth={1120}
              mx="auto"
              px="6"
              justifyContent="space-between"
            >
              <Box alignItems="center">
                <Heading as="h4" size="md" mb="0">
                  Dados de contato dos representantes da empresa
                </Heading>
              </Box>
            </Flex>
            {newCorrentista?.map((item, index) => (
              <Flex direction="column">
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6">
                  <FormControl>
                    <SimpleGrid
                      columns={isWideVersion ? 3 : 1}
                      spacing={2}
                      bg={bg}
                      border="1px"
                      borderColor="gray.200"
                      borderRadius={8}
                      mb={'2'}
                    >
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="cnpj">Nome</FormLabel>
                        <Input
                          id="nome"
                          borderColor="gray.300"
                          value={item.nome}
                          disabled
                        />
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="razaoSocial">Email</FormLabel>
                        <Input
                          id="email"
                          type="email"
                          borderColor="gray.300"
                          value={item?.email}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'email')
                          }
                        />
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="razaoSocial2">
                          Rede Social
                        </FormLabel>
                        <Input
                          id="redeSocial2"
                          type="text"
                          borderColor="gray.300"
                          value={item.redeSocial}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'redeSocial')
                          }
                        />
                      </Box>
                    </SimpleGrid>
                    <SimpleGrid
                      columns={isWideVersion ? 4 : 1}
                      spacing={2}
                      bg={bg}
                      border="1px"
                      borderColor="gray.200"
                      borderRadius={8}
                      mb={'2'}
                    >
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="ddd">DDI</FormLabel>
                        <Select
                          placeholder="Selecione uma opção"
                          size="md"
                          variant="outline"
                          value={item.ddi1}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'ddi1')
                          }
                        >
                          {Object.values(paises)?.map((item, index) => (
                            <option key={index} value={item?.ddi}>
                              {`${item.pais} (+${item.ddi})`}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="ddd">DDD</FormLabel>
                        <Input
                          as={InputMask}
                          maskChar={null}
                          id="ddd"
                          type="text"
                          borderColor="gray.300"
                          mask="99"
                          value={item.ddd1}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'ddd1')
                          }
                        />
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="razaoSocial">Telefone</FormLabel>
                        <Input
                          as={InputMask}
                          maskChar={null}
                          id="razaoSocial"
                          type="text"
                          borderColor="gray.300"
                          mask="99999999999"
                          value={item.telefone1}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'telefone1')
                          }
                        />
                      </Box>
                      <br />
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="ddd">DDI</FormLabel>
                        <Select
                          placeholder="Selecione uma opção"
                          size="md"
                          variant="outline"
                          value={item.ddi2}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'ddi2')
                          }
                        >
                          {Object.values(paises)?.map((item, index) => (
                            <option key={index} value={item?.ddi}>
                              {`${item.pais} (+${item.ddi})`}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="ddd">DDD</FormLabel>
                        <Input
                          as={InputMask}
                          maskChar={null}
                          id="ddd"
                          type="text"
                          borderColor="gray.300"
                          mask="99"
                          value={item.ddd2}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'ddd2')
                          }
                        />
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="razaoSocial">
                          Telefone 2 (opcional)
                        </FormLabel>
                        <Input
                          as={InputMask}
                          maskChar={null}
                          id="telefone2"
                          type="text"
                          borderColor="gray.300"
                          mask="99999999999"
                          value={item.telefone2}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'telefone2')
                          }
                        />
                      </Box>
                    </SimpleGrid>
                    <SimpleGrid
                      columns={isWideVersion ? 4 : 1}
                      spacing={2}
                      bg={bg}
                      border="1px"
                      borderColor="gray.200"
                      borderRadius={8}
                      mb={'2'}
                    >
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="ddd">DDI</FormLabel>
                        <Select
                          placeholder="Selecione uma opção"
                          size="md"
                          variant="outline"
                          value={item.ddi3}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'ddi3')
                          }
                        >
                          {Object.values(paises)?.map((item, index) => (
                            <option key={index} value={item?.ddi}>
                              {`${item.pais} (+${item.ddi})`}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="ddd">DDD</FormLabel>
                        <Input
                          as={InputMask}
                          maskChar={null}
                          id="ddd"
                          type="text"
                          borderColor="gray.300"
                          mask="99"
                          value={item.ddd3}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'ddd3')
                          }
                        />
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="razaoSocial">
                          Telefone 3 (opcional)
                        </FormLabel>
                        <Input
                          as={InputMask}
                          maskChar={null}
                          id="telefone3"
                          type="text"
                          borderColor="gray.300"
                          mask="99999999999"
                          value={item.telefone3}
                          onChange={e =>
                            handleChange(index, e?.target?.value, 'telefone3')
                          }
                        />
                      </Box>
                    </SimpleGrid>
                  </FormControl>
                </Flex>
              </Flex>
            ))}
          </>
        )}
        <Flex
          w="100%"
          my="6"
          maxWidth={1120}
          mx="auto"
          px="6"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              leftIcon={<ArrowBackIcon />}
              mr="5"
              colorScheme="teal"
              variant="outline"
              onClick={() => pageTwoToPageOne()}
            >
              Anterior
            </Button>
            <Button
              rightIcon={<ArrowForwardIcon />}
              colorScheme="teal"
              onClick={() => pageTwoToPageThree()}
              disabled={
                page2RepresentanteEmail &&
                page2RepresentanteDDD &&
                page2RepresentanteTelefone != null
                  ? false
                  : true
              }
            >
              Próximo
            </Button>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
