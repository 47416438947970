import React, { useState } from 'react'

import InputMask from 'react-input-mask'
import { ArrowForwardIcon, ArrowBackIcon, AddIcon, PhoneIcon } from '@chakra-ui/icons'
import {
  useBreakpointValue,
  Heading,
  Stack,
  Flex,
  SimpleGrid,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Avatar,
  Button,
  InputGroup,
  InputLeftElement,
  useColorModeValue,  
} from '@chakra-ui/react'
import { useForm } from '../../contexts/FormContext'

export default function Pagina1() {

  const bg = useColorModeValue('#fff', 'gray.800')
  const topNumer = useColorModeValue('#fff', 'gray.800')

    const { pageOne, setPageOne } = useForm()
    const { pageTwo, setPageTwo } = useForm()
    const { pageThree, setPageThree } = useForm()
    const { newCorrentista, setNewCorrentista } = useForm()
    const { checkTipoPessoa, setCheckTipoPessoa } = useForm();
    const { page1RepresentanteNome, setPage1RepresentanteNome } = useForm()
    const { page2RepresentanteEmail, setPage2RepresentanteEmail } = useForm()
    const { page2RepresentanteRedeSocial, setPage2RepresentanteRedeSocial } = useForm()
    const { page2RepresentanteDDD, setPage2RepresentanteDDD } = useForm()
    const { page2RepresentanteDDD2, setPage2RepresentanteDDD2 } = useForm()
    const { page2RepresentanteDDD3, setPage2RepresentanteDDD3 } = useForm()
    const { page2RepresentanteTelefone, setPage2RepresentanteTelefone } = useForm()
    const { page2RepresentanteTelefone2, setPage2RepresentanteTelefone2 } = useForm()
    const { page2RepresentanteTelefone3, setPage2RepresentanteTelefone3 } = useForm()
    const { page1Representante2Nome, setPage1Representante2Nome } = useForm()
    const { page2Representante2Email, setPage2Representante2Email } = useForm()
    const { page2Representante2RedeSocial, setPage2Representante2RedeSocial } = useForm()
    const { page2Representante2DDD, setPage2Representante2DDD } = useForm()
    const { page2Representante2DDD2, setPage2Representante2DDD2 } = useForm()
    const { page2Representante2DDD3, setPage2Representante2DDD3 } = useForm()
    const { page2Representante2Telefone, setPage2Representante2Telefone } = useForm()
    const { page2Representante2Telefone2, setPage2Representante2Telefone2 } = useForm()
    const { page2Representante2Telefone3, setPage2Representante2Telefone3 } = useForm()

    // const [checkTipoPessoa, setCheckTipoPessoa] = useState(true)

    const handleChangePessoa = event => {
        setCheckTipoPessoa(event.target.checked ? (false) : (true));
    };

    function isNewCorrentista() {
        setNewCorrentista(true)
    }

    function pageTwoToPageOne() {
        setPageOne(true)
        setPageTwo(false)
    }

    function pageTwoToPageThree() {
        setPageThree(true)
        setPageTwo(false)
    }

    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true
      })

    return (
        <>
        {isWideVersion && (
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >
              <Box display="flex">
                <Stack direction='row'>
                  <Avatar name='1' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Heading as='h4' size='sm' my="2" ml="2">
                {checkTipoPessoa ? 'Dados da pessoa jurídica' : 'Dados da pessoa física'}
                </Heading>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='2' size='sm' bg='#65c4d5' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de contato
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='3' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de endereço
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='4' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Minha conta
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='5' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Termo
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='6' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Senha
                </Text>
              </Box>
    
            </Flex>
          )}
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >        
              <Box alignItems='center'>
                <Heading as='h4' size='md' mb="0">
                    Dados de contato do representante da empresa
                </Heading>  
              </Box>
            </Flex>
            <Flex direction="column">          
    
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6">            
    
                    <FormControl>
                      
                        <SimpleGrid columns={isWideVersion ? 3 : 1} spacing={2} bg={bg} border='1px' borderColor='gray.200' borderRadius={8}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='cnpj'>Nome</FormLabel>
                                <Input
                                    id='nome'
                                    borderColor='gray.300'
                                    value={page1RepresentanteNome}
                                    disabled 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Email</FormLabel>
                                <Input 
                                    id='razaoSocial'
                                    type='email'
                                    borderColor='gray.300'
                                    value={page2RepresentanteEmail}
                                    onChange={(e) => setPage2RepresentanteEmail(e.target.value)} 
                                />
                              </Box>    
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Rede Social</FormLabel>
                                <Input 
                                    id='razaoSocial'
                                    type='text'
                                    borderColor='gray.300'
                                    value={page2RepresentanteRedeSocial}
                                    onChange={(e) => setPage2RepresentanteRedeSocial(e.target.value)}  
                                />
                              </Box> 
                            </SimpleGrid>  
                            <SimpleGrid columns={isWideVersion ? 4 : 1} spacing={2} bg={bg} border='1px' borderColor='gray.200' borderRadius={8}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                  
                              >
                                <FormLabel htmlFor='ddd'>DDD</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='ddd'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99"
                                    value={page2RepresentanteDDD}
                                    onChange={(e) => setPage2RepresentanteDDD(e.target.value)}  
                                />
                               </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                  
                              >
                                <FormLabel htmlFor='telefone'>Telefone</FormLabel>
                                <InputGroup>
                                <InputLeftElement
                                  pointerEvents='none'
                                  children={<PhoneIcon color='gray.300' />}
                                />
                                  <Input 
                                      as={InputMask}
                                      maskChar={null}
                                      id='telefone'
                                      type='tel'
                                      borderColor='gray.300'
                                      mask="999999999"
                                      value={page2RepresentanteTelefone}
                                      onChange={(e) => setPage2RepresentanteTelefone(e.target.value)}  
                                  />
                                </InputGroup>
                               </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                   
                              >
                                <FormLabel htmlFor='ddd2'>DDD do telefone 2 (Opcional)</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='ddd2'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99"
                                    value={page2RepresentanteDDD2}
                                    onChange={(e) => setPage2RepresentanteDDD2(e.target.value)}  
                                />
                               </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                   
                              >
                                <FormLabel htmlFor='telefone2'>Telefone 2 (opcional)</FormLabel>
                                <InputGroup>
                                <InputLeftElement
                                  pointerEvents='none'
                                  children={<PhoneIcon color='gray.300' />}
                                />
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='telefone2'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="999999999"
                                    value={page2RepresentanteTelefone2}
                                    onChange={(e) => setPage2RepresentanteTelefone2(e.target.value)}  
                                />
                                </InputGroup>
                               </Box>
                              </SimpleGrid> 
                              <SimpleGrid columns={isWideVersion ? 4 : 1} spacing={2} bg={bg} border='1px' borderColor='gray.200' borderRadius={8}>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                   
                              >
                                <FormLabel htmlFor='ddd3'>DDD do telefone 3 (opcional)</FormLabel>
                                <InputGroup>
                                <InputLeftElement
                                  pointerEvents='none'
                                  children={<PhoneIcon color='gray.300' />}
                                />
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='ddd3'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99"
                                    value={page2RepresentanteDDD3}
                                    onChange={(e) => setPage2RepresentanteDDD3(e.target.value)}  
                                />
                                </InputGroup>
                               </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                   
                              >
                                <FormLabel htmlFor='telefone3'>Telefone 3 (opcional)</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='telefone3'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="999999999"
                                    value={page2RepresentanteTelefone3}
                                    onChange={(e) => setPage2RepresentanteTelefone3(e.target.value)}  
                                />
                               </Box>  
                        </SimpleGrid>
                        
                    </FormControl>
                    
                </Flex> 
                {newCorrentista && (
            <>
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >        
              <Box alignItems='center'>
                <Heading as='h4' size='md' mb="0">
                    Dados de contato do segundo representante da empresa
                </Heading>  
              </Box>
            </Flex>
            <Flex direction="column">          
    
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6">            
    
                    <FormControl>
                      
                        <SimpleGrid columns={isWideVersion ? 3 : 1} spacing={2} bg={bg} border='1px' borderColor='gray.200' borderRadius={8}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='cnpj'>Nome</FormLabel>
                                <Input 
                                    id='nome' 
                                    borderColor='gray.300'
                                    value={page1Representante2Nome}
                                    disabled 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Email</FormLabel>
                                <Input 
                                    id='email'
                                    type='email'
                                    borderColor='gray.300'
                                    value={page2Representante2Email}
                                    onChange={(e) => setPage2Representante2Email(e.target.value)} 
                                />
                              </Box>    
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial2'>Rede Social</FormLabel>
                                <Input 
                                    id='redeSocial2'
                                    type='text'
                                    borderColor='gray.300'
                                    value={page2Representante2RedeSocial}
                                    onChange={(e) => setPage2Representante2RedeSocial(e.target.value)}  
                                />
                              </Box> 
                        </SimpleGrid>
                        <SimpleGrid columns={isWideVersion ? 4 : 1} spacing={2} bg={bg} border='1px' borderColor='gray.200' borderRadius={8}>      
                        <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                  
                              >
                                <FormLabel htmlFor='ddd'>DDD</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='ddd'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99"
                                    value={page2Representante2DDD}
                                    onChange={(e) => setPage2Representante2DDD(e.target.value)}  
                                />
                               </Box>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                  
                              >
                                <FormLabel htmlFor='razaoSocial'>Telefone</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='razaoSocial'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99999999999"
                                    value={page2Representante2Telefone}
                                    onChange={(e) => setPage2Representante2Telefone(e.target.value)}  
                                />
                               </Box>
                               <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                  
                              >
                                <FormLabel htmlFor='ddd'>DDD</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='ddd'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99"
                                    value={page2Representante2DDD2}
                                    onChange={(e) => setPage2Representante2DDD2(e.target.value)}  
                                />
                               </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                   
                              >
                                <FormLabel htmlFor='razaoSocial'>Telefone 2 (opcional)</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='telefone2'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99999999999"
                                    value={page2Representante2Telefone2}
                                    onChange={(e) => setPage2Representante2Telefone2(e.target.value)}  
                                />
                               </Box> 
                          </SimpleGrid>
                          <SimpleGrid columns={isWideVersion ? 4 : 1} spacing={2} bg={bg} border='1px' borderColor='gray.200' borderRadius={8}>      
                          <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                  
                              >
                                <FormLabel htmlFor='ddd'>DDD</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='ddd'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99"
                                    value={page2Representante2DDD3}
                                    onChange={(e) => setPage2RepresentanteDDD(e.target.value)}  
                                />
                               </Box>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"                                                                   
                              >
                                <FormLabel htmlFor='razaoSocial'>Telefone 3 (opcional)</FormLabel>
                                <Input 
                                    as={InputMask}
                                    maskChar={null}
                                    id='telefone3'
                                    type='text'
                                    borderColor='gray.300'
                                    mask="99999999999"
                                    value={page2Representante2Telefone3}
                                    onChange={(e) => setPage2Representante2Telefone3(e.target.value)}  
                                />
                               </Box>  
                        </SimpleGrid>
                        
                    </FormControl>
                    
                </Flex>   
            </Flex>
          </>
          )}
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6"  justifyContent="flex-end">
                        <Box>
                            <Button leftIcon={<ArrowBackIcon />} mr="5" colorScheme='teal' variant='outline' onClick={() => pageTwoToPageOne()}>
                            Anterior
                            </Button>
                            <Button 
                              rightIcon={<ArrowForwardIcon />} 
                              colorScheme='teal' 
                              onClick={() => pageTwoToPageThree()}
                              disabled={page2RepresentanteEmail && page2RepresentanteDDD && page2RepresentanteTelefone != null ? false : true}
                            >
                            Próximo
                            </Button>
                        </Box>
                    </Flex>   
            </Flex>
            
      </>       
    )
}