import React, { useEffect, useState } from 'react';

import { api_multipague_configuracao } from '../../services/api';

import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { Divider, Progress } from '@chakra-ui/react';
import { ArrowForwardIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  useBreakpointValue,
  Heading,
  Stack,
  Flex,
  SimpleGrid,
  Box,
  Switch,
  Text,
  Input,
  FormControl,
  FormLabel,
  Avatar,
  Button,
  Checkbox,
  useColorMode,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from '../../contexts/FormContext';

export default function Pagina1() {
  const [cpfConsultar, setCpfConsultar] = useState('');
  const [cnpjConsultar, setCnpjConsultar] = useState('');
  const [carregandoCnpj, setCarregandoCnpj] = useState(false);
  const [carregandoCpf, setCarregandoCpf] = useState(false);

  const toast = useToast();
  const bg = useColorModeValue('#fff', 'gray.800');
  const topNumer = useColorModeValue('#fff', 'gray.800');

  useEffect(() => {
    if (cnpjConsultar.length === 18) {
      setCarregandoCnpj(true);
      const URL = `/empresa/consultarCnpj/${cnpjConsultar.replace(
        /[^0-9]+/g,
        '',
      )}`;
      api_multipague_configuracao
        .get(URL)
        .then(response => {
          setPage1Pessoa(response.data);
          setPage1RazaoSocial(response.data.nome);
          setPage1NomeFantasia(response.data.fantasia);
          setPage1Cnpj(response.data.cnpj);
          setPage1Cep(response.data.cep);
          setPage1Bairro(response.data.bairro);
          setPage1Logradouro(response.data.logradouro);
          setPage1Complemento(response.data.complemento);
          setPage1Numero(response.data.numero);
          setPage1Municipio(response.data.municipio);
          setPage1Uf(response.data.uf);
          setPage1Abertura(
            new Intl.DateTimeFormat('en-CA').format(
              new Date(response.data.abertura),
            ),
          );
          setPage1Situacao(response.data.situacao);
          setCarregandoCnpj(false);
        })
        .catch(function (error) {
          console.log('Esse Erro: ', error);
          setCarregandoCnpj(false);
          if (error.response.status == 500 || error.response.status == 400) {
            toast({
              title: 'Ops!',
              description: error.response.data.mensagem,
              status: 'warning',
              duration: 3000,
              isClosable: true,
            });
          }
        });
    }
  }, [cnpjConsultar]);

  useEffect(() => {
    if (cpfConsultar.length === 14) {
      setCarregandoCpf(true);
      const URL = `/pessoa-fisica/consultarCpf/${cpfConsultar.replace(
        /[^0-9]+/g,
        '',
      )}`;
      api_multipague_configuracao
        .get(URL)
        .then(response => {
          setPage1RepresentanteNome(response.data.nome);
          setCarregandoCpf(false);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log('Esse Erro: ', error);
          setCarregandoCpf(false);
          if (error.response.status == 500 || error.response.status == 400) {
            toast({
              title: 'Ops!',
              description: error.response.data.mensagem,
              status: 'warning',
              duration: 3000,
              isClosable: true,
            });
          }
        });
    }
  }, [cpfConsultar]);

  function consultarCpf(e) {
    // let cpf = e.target.value
    const URL = `/usuario/${page1RepresentanteCpf.replace(/[^0-9]+/g, '')}`;
    api_multipague_configuracao
      .get(URL)
      .then(response => {
        setPossuiConta(true);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const { pageOne, setPageOne } = useForm();
  const { pageTwo, setPageTwo } = useForm();
  const { possuiConta, setPossuiConta } = useForm();
  const { checkTipoPessoa, setCheckTipoPessoa } = useForm();
  const { newCorrentista, setNewCorrentista } = useForm([]);
  const { page1Cnpj, setPage1Cnpj } = useForm();
  const { page1Pessoa, setPage1Pessoa } = useForm();
  const { page1RazaoSocial, setPage1RazaoSocial } = useForm();
  const { page1NomeFantasia, setPage1NomeFantasia } = useForm();
  const { page1InscricaoEstadual, setPage1InscricaoEstadual } = useForm();
  const { page1InscricaoMunicipal, setPage1InscricaoMunicipal } = useForm();
  const { page1Cep, setPage1Cep } = useForm();
  const { page1Bairro, setPage1Bairro } = useForm();
  const { page1Logradouro, setPage1Logradouro } = useForm();
  const { page1Complemento, setPage1Complemento } = useForm();
  const { page1Numero, setPage1Numero } = useForm();
  const { page1Municipio, setPage1Municipio } = useForm();
  const { page1Uf, setPage1Uf } = useForm();
  const { page1Abertura, setPage1Abertura } = useForm();
  const { page1PaginaWeb, setPage1PaginaWeb } = useForm();
  const { cpfPessoaFisica, setCpfPessoaFisica } = useForm();
  const { page1Situacao, setPage1Situacao } = useForm();
  const { page1RepresentanteCpf, setPage1RepresentanteCpf } = useForm();
  const { page1RepresentanteRg, setPage1RepresentanteRg } = useForm();
  const { page1RepresentanteNome, setPage1RepresentanteNome } = useForm();
  const { page1TipoDePessoa, setPage1TipoDePessoa } = useForm();

  const handleChangePessoa = event => {
    setCheckTipoPessoa(event.target.checked ? false : true);
    setPage1TipoDePessoa(event.target.checked ? 'F' : 'J');
  };

  function handleChangeCpf(event) {
    setCpfPessoaFisica(event.target.value);
    setPage1RepresentanteCpf(event.target.value);
  }

  function isNewCorrentista() {
    setNewCorrentista(prev => [
      ...prev,
      {
        index: prev.length > 0 ? prev[prev.length - 1].index + 1 : 0,
        cpf: '',
        rg: '',
        nome: '',
        contaSolidaria: false,
        email: null,
        redeSocial: '',
        ddi1: null,
        ddi2: null,
        ddi3: null,
        ddd1: null,
        ddd2: null,
        ddd3: null,
        telefone1: null,
        telefone2: null,
        telefone3: null,
      },
    ]);
  }

  const handleChange = (index, e, name) => {
    setNewCorrentista(prev =>
      prev.map((correntista, i) =>
        i === index ? { ...correntista, [name]: e.target.value } : correntista,
      ),
    );
  };

  function removeCorrentista() {
    setNewCorrentista(false);
  }

  function removeCorrentistaArray(indexToRemove) {
    setNewCorrentista(prev =>
      prev.filter((_, index) => index !== indexToRemove),
    );
  }

  function pageOneToPageTwo() {
    setPageOne(false);
    setPageTwo(true);
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <>
      {isWideVersion && (
        <Flex
          w="100%"
          my="6"
          maxWidth={1120}
          mx="auto"
          px="6"
          justifyContent="space-between"
        >
          <Box display="flex">
            <Stack direction="row">
              <Avatar name="1" size="sm" bg="#65c4d5" color={topNumer} />
            </Stack>
            <Heading as="h4" size="sm" my="2" ml="2">
              {checkTipoPessoa
                ? 'Dados da pessoa jurídica'
                : 'Dados da pessoa física'}
            </Heading>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="2" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Dados de contato
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="3" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Dados de endereço
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="4" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Minha conta
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="5" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Termo
            </Text>
          </Box>

          <Box display="flex">
            <Stack direction="row" ml={5}>
              <Avatar name="6" size="sm" bg="gray.300" color={topNumer} />
            </Stack>
            <Text fontSize="sm" my="2" ml="2">
              Senha
            </Text>
          </Box>
        </Flex>
      )}
      <Flex
        w="100%"
        my="6"
        maxWidth={1120}
        mx="auto"
        px="6"
        justifyContent="space-between"
      >
        <Box alignItems="center">
          <Heading as="h4" size="md" mb="0">
            {checkTipoPessoa
              ? 'Dados da pessoa jurídica'
              : 'Dados da pessoa física'}
          </Heading>
        </Box>
        <Box mt="4">
          <FormControl display="flex" alignItems="center">
            {isWideVersion && (
              <FormLabel htmlFor="alterar-tipo-pessoa" mb="0">
                {checkTipoPessoa
                  ? 'Alterar para pessoa física'
                  : 'Alterar para pessoa jurídica'}
              </FormLabel>
            )}
            <Switch
              size="lg"
              id="alterar-tipo-pessoa"
              checked={checkTipoPessoa}
              onChange={handleChangePessoa}
            />
          </FormControl>
        </Box>
      </Flex>
      <Flex direction="column">
        <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6">
          <FormControl>
            {checkTipoPessoa ? (
              <SimpleGrid
                columns={isWideVersion ? 2 : 1}
                spacing={2}
                bg={bg}
                border="1px"
                borderColor="gray.200"
                borderRadius={8}
              >
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="cnpj">CNPJ</FormLabel>
                  <Input
                    as={InputMask}
                    id="cnpj"
                    mask="99.999.999/9999-99"
                    borderColor="gray.300"
                    value={page1Cnpj}
                    onBlur={e => {
                      setCnpjConsultar(e.target.value);
                    }}
                    onChange={e => setPage1Cnpj(e.target.value)}
                  />
                  {carregandoCnpj && <Progress size="xs" isIndeterminate />}
                </Box>
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="razaoSocial">Razão Social</FormLabel>
                  <Input
                    id="razaoSocial"
                    type="text"
                    borderColor="gray.300"
                    value={page1RazaoSocial}
                    onChange={e => setPage1RazaoSocial(e.target.value)}
                  />
                </Box>
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="nomeFantasia">Nome fantasia</FormLabel>
                  <Input
                    id="nomeFantasia"
                    type="text"
                    borderColor="gray.300"
                    value={page1NomeFantasia}
                    onChange={e => setPage1NomeFantasia(e.target.value)}
                  />
                </Box>
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="inscricaoEstadual">
                    Inscrição estadual
                  </FormLabel>
                  <Input
                    id="inscricaoEstadual"
                    type="text"
                    borderColor="gray.300"
                    value={page1InscricaoEstadual}
                    onChange={e => setPage1InscricaoEstadual(e.target.value)}
                  />
                </Box>
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="inscricaoMunicipal">
                    Inscrição municipal
                  </FormLabel>
                  <Input
                    id="inscricaoMunicipal"
                    type="text"
                    borderColor="gray.300"
                    value={page1InscricaoMunicipal}
                    onChange={e => setPage1InscricaoMunicipal(e.target.value)}
                  />
                </Box>
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="dataAbertura">Data de abertura</FormLabel>
                  <Input
                    id="dataAbertura"
                    type="date"
                    borderColor="gray.300"
                    value={page1Abertura}
                    onChange={e => setPage1Abertura(e.target.value)}
                  />
                </Box>
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="paginaWeb">Página web</FormLabel>
                  <Input
                    id="paginaWeb"
                    type="text"
                    borderColor="gray.300"
                    value={page1PaginaWeb}
                    onChange={e => setPage1PaginaWeb(e.target.value)}
                  />
                </Box>
              </SimpleGrid>
            ) : (
              <SimpleGrid
                columns={isWideVersion ? 3 : 1}
                spacing={2}
                bg={bg}
                border="1px"
                borderColor="gray.200"
                borderRadius={8}
              >
                <Box p={['3', '3']} borderRadius={8} pb="4">
                  <FormLabel htmlFor="cpf">CPF</FormLabel>
                  <Input
                    as={InputMask}
                    id="cnpj"
                    mask="999.999.999-99"
                    borderColor="gray.300"
                    value={cpfPessoaFisica}
                    onBlur={e => {
                      setCpfConsultar(e.target.value);
                    }}
                    onChange={handleChangeCpf}
                  />
                  {carregandoCpf && <Progress size="xs" isIndeterminate />}
                </Box>
              </SimpleGrid>
            )}
          </FormControl>
        </Flex>

        <Flex
          w="100%"
          maxWidth={1120}
          mx="auto"
          px="6"
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h4" size="md">
              Representante legal
            </Heading>
          </Box>
          <Box mt="2">
            {isWideVersion ? (
              <Button
                rightIcon={
                  newCorrentista.length >= 0 ? <AddIcon /> : <MinusIcon />
                }
                colorScheme="#394C59"
                variant="outline"
                onClick={
                  newCorrentista.length >= 0
                    ? () => isNewCorrentista()
                    : () => removeCorrentista()
                }
              >
                Adicionar novo correntista
              </Button>
            ) : (
              <Button
                colorScheme="#394C59"
                variant="outline"
                onClick={() => isNewCorrentista()}
              >
                <AddIcon />
              </Button>
            )}
          </Box>
        </Flex>

        <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6">
          <FormControl>
            <SimpleGrid
              columns={isWideVersion ? 3 : 1}
              spacing={2}
              bg={bg}
              border="1px"
              borderColor="gray.200"
              borderRadius={8}
            >
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="cnpj">CPF (Apenas números)</FormLabel>
                <Input
                  as={InputMask}
                  id="cpf"
                  mask="999.999.999-99"
                  borderColor="gray.300"
                  value={page1RepresentanteCpf}
                  onBlur={e => {
                    setCpfConsultar(e.target.value);
                  }}
                  onChange={handleChangeCpf}
                />
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="razaoSocial">RG (Apenas números)</FormLabel>
                <Input
                  id="razaoSocial"
                  type="text"
                  borderColor="gray.300"
                  value={page1RepresentanteRg}
                  onChange={e => setPage1RepresentanteRg(e.target.value)}
                  onBlur={consultarCpf}
                />
              </Box>
              <Box p={['3', '3']} borderRadius={8} pb="4">
                <FormLabel htmlFor="nomeFantasia">Nome</FormLabel>
                <Input
                  id="nomeFantasia"
                  type="text"
                  borderColor="gray.300"
                  value={page1RepresentanteNome}
                  onChange={e => setPage1RepresentanteNome(e.target.value)}
                />
              </Box>
            </SimpleGrid>
          </FormControl>
        </Flex>
        <Flex
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          width={'57%'}
          mx="auto"
          p={'3'}
        >
          {newCorrentista?.map((item, index) => (
            <SimpleGrid
              spacing={2}
              bg={bg}
              border="1px"
              borderColor="gray.200"
              borderRadius={8}
              marginBottom={'2'}
            >
              <Flex
                w="100%"
                maxWidth={1120}
                mx="auto"
                px="6"
                justifyContent="space-between"
              >
                <Box
                  width={'100%'}
                  display={'flex'}
                  p={2}
                  mt={3}
                  justifyContent={'space-between'}
                >
                  <Heading as="h4" size="md" pt={'3'}>
                    {index + 2}º representante legal
                  </Heading>

                  <Button
                    colorScheme="#394C59"
                    variant="outline"
                    rightIcon={<MinusIcon />}
                    onClick={() => removeCorrentistaArray(index)}
                  >
                    Remover representante
                  </Button>
                </Box>
              </Flex>
              <Flex direction="column">
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6">
                  <FormControl>
                    <SimpleGrid
                      columns={isWideVersion ? 3 : 1}
                      spacing={2}
                      bg={bg}
                    >
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="cnpj">
                          CPF (Apenas números)
                        </FormLabel>
                        <Input
                          as={InputMask}
                          id="cnpj"
                          mask="999.999.999-99"
                          borderColor="gray.300"
                          value={item.cpf}
                          onChange={e => handleChange(index, e, 'cpf')}
                        />
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="razaoSocial">
                          RG (Apenas números)
                        </FormLabel>
                        <Input
                          id="razaoSocial"
                          type="text"
                          borderColor="gray.300"
                          value={item.rg}
                          onChange={e => handleChange(index, e, 'rg')}
                        />
                      </Box>
                      <Box p={['3', '3']} borderRadius={8} pb="4">
                        <FormLabel htmlFor="nomeFantasia">Nome</FormLabel>
                        <Input
                          id="nomeFantasia"
                          type="text"
                          borderColor="gray.300"
                          value={item.nome}
                          onChange={e => handleChange(index, e, 'nome')}
                        />
                      </Box>
                    </SimpleGrid>
                  </FormControl>
                </Flex>
                <Stack
                  spacing={5}
                  direction="row"
                  w="100%"
                  maxWidth={1120}
                  mx="auto"
                  px="6"
                  marginBottom={'2'}
                >
                  <Checkbox
                    colorScheme="green"
                    size="lg"
                    checked={item.contaSolidaria}
                    onChange={event =>
                      setNewCorrentista(prev =>
                        prev.map((correntista, i) =>
                          i === index
                            ? {
                                ...correntista,
                                contaSolidaria: event.target.checked,
                              }
                            : correntista,
                        ),
                      )
                    }
                  >
                    Assinatura solidária (representantes assinam em conjunto)
                  </Checkbox>
                </Stack>
              </Flex>
              <Flex
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Divider
                  style={{
                    width: '70%',
                  }}
                />
              </Flex>
            </SimpleGrid>
          ))}
        </Flex>
        <Flex
          w="100%"
          my="6"
          maxWidth={1120}
          mx="auto"
          px="6"
          justifyContent="flex-end"
        >
          <Box>
            {checkTipoPessoa ? (
              <Button
                rightIcon={<ArrowForwardIcon />}
                colorScheme="teal"
                onClick={() => pageOneToPageTwo()}
                disabled={
                  page1Cnpj &&
                  page1RepresentanteCpf &&
                  page1RepresentanteRg &&
                  page1Abertura &&
                  page1RepresentanteNome != null
                    ? false
                    : true
                }
              >
                Próximo
              </Button>
            ) : (
              <Button
                rightIcon={<ArrowForwardIcon />}
                colorScheme="teal"
                onClick={() => pageOneToPageTwo()}
                disabled={
                  cpfPessoaFisica &&
                  page1RepresentanteNome &&
                  page1RepresentanteRg &&
                  page1RepresentanteCpf != null
                    ? false
                    : true
                }
              >
                Próximo
              </Button>
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
