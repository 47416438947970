import React, { useState } from 'react'

import { ArrowForwardIcon, ArrowBackIcon, CheckIcon } from '@chakra-ui/icons'
import {
  useBreakpointValue,
  Heading,
  Stack,
  Flex,
  SimpleGrid,
  RadioGroup,
  Radio,
  Box,
  Text,
  Textarea,
  FormControl,
  Avatar,
  Button,
  useColorModeValue
} from '@chakra-ui/react'
import { useForm } from '../../contexts/FormContext'

export default function Pagina5() {

  const bg = useColorModeValue('#fff', 'gray.800')
  const topNumer = useColorModeValue('#fff', 'gray.800')

    const [prosseguir, setProsseguir] = useState(false)
    const [value, setValue] = useState('')

    const { pageFour, setPageFour } = useForm()
    const { pageFive, setPageFive } = useForm()
    const { pageSix, setPageSix } = useForm()
    const { aceito, setAceito } = useForm(false)
    const { checkTipoPessoa, setCheckTipoPessoa } = useForm();

    const handleClickAceito = () => {
        setProsseguir(true);
        setValue
        setAceito(true)
    };

    function pageFiveToPageFour() {
        setPageFour(true)
        setPageFive(false)
    }

    function pageFiveToPageSix() {
        setPageFive(false)
        setPageSix(true)
    }

    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true
      })

    return (
        <>
        {isWideVersion && (
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >
              <Box display="flex">
                <Stack direction='row'>
                  <Avatar name='1' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Heading as='h4' size='sm' my="2" ml="2">
                  {checkTipoPessoa ? 'Dados da pessoa jurídica' : 'Dados da pessoa física'}
                </Heading>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='2' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de contato
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='3' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de endereço
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='4' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Minha conta
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='5' size='sm' bg='#65c4d5' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Termo
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='6' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Senha
                </Text>
              </Box>
    
            </Flex>
          )}
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >        
              <Box alignItems='center'>
                <Heading as='h4' size='md' mb="0">
                    Termo de Uso e Política de Privacidade
                </Heading>  
              </Box>
            </Flex>
            <Flex direction="column">          
    
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" border='1px' borderColor='gray.200' bg={bg} borderRadius={8}>            
    
                    <FormControl>
                      
                        <SimpleGrid columns={isWideVersion ? 1 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <Textarea                                    
                                    size='sm'
                                    w="100%"
                                    h="400px"
                                >
                                    TERMO DE CIÊNCIA E ANUÊNCIA ÀS CONDIÇÕES GERAIS DA CONTA DE PAGAMENTO MOVIMENTÁVEL POR TED E DOC



Considerando que:

(i) a MONEY PLUS é uma instituição financeira com capacidade e autorização para prestar serviços bancários entre eles abertura de Conta Pagamento;

(ii) o CLIENTE possui interesse em contratar a MONEY PLUS para a prestação dos serviços de abertura de Conta Pagamento e

(iii) para tal contratação o CLIENTE deve aderir ao instrumento de "Termos e Condições Gerais de Contratação da Money Plus para os Serviços de Abertura de Conta Pagamento ("Termos e Condições Gerais")

Resolvem as Partes celebrar este Termo de Adesão aos Termos e Condições Gerais ("Termo de Adesão"), que será regido pelos Termos e Condições Gerais e pelas seguintes cláusulas e condições:

1. ADESÃO

1.1. Por meio da celebração deste Termo de Adesão, o CLIENTE adere expressamente a todas as cláusulas e disposições constantes dos Termos e Condições Gerais, contratando, desta forma, os Serviços da MONEY PLUS para abertura de Conta Pagamento.

1.2. Salvo de outra forma definido no presente Termo de Adesão, os termos utilizados em letra maiúscula terão os significados a eles atribuídos nos Termos e Condições Gerais.

2. REMUNERAÇÃO


2.1. Pelos Serviços prestados pela MONEY PLUS ao CLIENTE, será devida a remuneração seguinte: [OU PREVER ANEXO]

2.2. O CLIENTE deverá fazer os pagamentos devidos líquidos de deduções e retenções fiscais de qualquer natureza, incluindo quaisquer outros tributos que porventura venham a incidir sobre os Serviços, bem como quaisquer majorações das alíquotas dos tributos já existentes.

3. DECLARAÇÕES E GARANTIAS


3.1. Sem prejuízo do disposto nos Termos e Condições Gerais, o CLIENTE declara e garante que: (i) teve acesso, leu e entendeu todo o disposto nos Termos e Condições Gerais, concordando integralmente e se obrigando a cumpri-los; (ii) conforme aplicável, os representantes legais que assinam este Termo de Adesão estão devidamente autorizados e, consequentemente, possuem poderes estatutários ou decorrentes do ato constitutivo aplicável e/ou delegados para assumir, em seu nome, as obrigações ora estabelecidas e, sendo mandatários, tiveram os poderes legitimamente outorgados, estando os respectivos mandatos em pleno vigor; (iii) este Termo de Adesão e os Termos e Condições Gerais e seus anexos constituem obrigações certa, liquida e exigível de acordo com os seus termos e condições, com força de título executivo extrajudicial nos termos da Lei nº 13.105, de 16 de março de 2015, conforme alterada.

4. VIGÊNCIA E RESCISÃO


4.1. As cláusulas, obrigações e direitos previstos nos Termos e Condições Gerais e neste Termo de Adesão entrarão em vigor entre as Partes a partir da data de assinatura deste Termo de Adesão e vigorarão por prazo indeterminado.

4.2. Este Termo de Adesão pode ser resilido a qualquer momento, por quaisquer das Partes, por qualquer motivo que seja de importância das Partes, sem direito a compensações ou indenizações (exceto pelo pagamento pelos Serviços prestados até a data em que o respectivo Termo de Adesão tiver seus efeitos encerrados), mediante envio de denúncia escrita com antecedência mínima de 30 (trinta) dias contados do recebimento do comunicado pela outra Parte.

4.3. Ficará facultado à MONEY PLUS completar, se o CLIENTE assim desejar, mediante autorização escrita deste, os negócios já iniciados cuja liquidação deva ocorrer após o fim do prazo de denúncia mencionado acima, recebendo normalmente os valores relativos à prestação dos Serviços de Custódia.


5. DISPOSIÇÕES GERAIS


5.1. O presente Termo de Adesão seguirá as Disposições Gerais presentes nos Termos e Condições Gerais.

5.2. Os termos iniciados em letra maiúscula utilizados neste Termo de Adesão, salvo se de outra forma definidos neste Termo de Adesão, terão o mesmo significado que lhes foi atribuído pelos Termos e Condições Gerais.

5.3. As Partes elegem o foro da Comarca da Capital do Estado de São Paulo, com exclusão de qualquer outro, por mais privilegiado que seja, para conhecer as questões e discussões decorrentes dos Termos e Condições Gerais e do Termo de Adesão.

5.4. Os Termos e Condições Gerais e o presente Termo de Adesão serão regidos pela lei brasileira.
                                </Textarea>    
                              </Box>  
                        </SimpleGrid>                        
                    </FormControl>                    
                </Flex> 
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6"  justifyContent="flex-end">
                    <Box>
                      <Button variant={aceito ? "solid" : "outline"} rightIcon={aceito ? <CheckIcon /> : ""} colorScheme='teal' onClick={handleClickAceito}>
                          Eu aceito
                      </Button>
                    </Box>
                </Flex>   
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6"  justifyContent="flex-end">
                    <Box>
                        <Button leftIcon={<ArrowBackIcon />} mr="5" colorScheme='teal' variant='outline' onClick={() => pageFiveToPageFour()}>
                        Anterior
                        </Button>
                        <Button disabled={!aceito} rightIcon={<ArrowForwardIcon />} colorScheme='teal' onClick={() => pageFiveToPageSix()}>
                        Próximo
                        </Button>
                    </Box>
                </Flex>   
            </Flex>
        </>    
    )
}