import React, { useState, useEffect } from 'react'

import InputMask from 'react-input-mask'
import { ArrowForwardIcon, ArrowBackIcon, AddIcon } from '@chakra-ui/icons'
import {
  useBreakpointValue,
  Heading,
  Stack,
  Flex,
  SimpleGrid,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Select,
  Avatar,
  Button,
  useColorModeValue
} from '@chakra-ui/react'
import { useForm } from '../../contexts/FormContext'
import { api_multipague_cep } from '../../services/api';

export default function Pagina1() {

  const bg = useColorModeValue('#fff', 'gray.800')
  const topNumer = useColorModeValue('#fff', 'gray.800')

    const { registerForm, setRegisterForm } = useForm()
    const { pageTwo, setPageTwo } = useForm()
    const { pageThree, setPageThree } = useForm()
    const { pageFour, setPageFour } = useForm()
    const { newCorrentista, setNewCorrentista } = useForm()
    const { checkTipoPessoa, setCheckTipoPessoa } = useForm();

    const {page3Cep, setPage3Cep} = useForm()
    const {page3Bairro, setPage3Bairro} = useForm()
    const {page3TipoLogradouro, setPage3TipoLogradouro} = useForm()
    const {page3Logradouro, setPage3Logradouro} = useForm()
    const {page3Complemento, setPage3Complemento} = useForm()
    const {page3Numero, setPage3Numero} = useForm()
    const {page3Cidade, setPage3Cidade} = useForm()
    const {page3Uf, setPage3Uf} = useForm()
    const {page3Pais, setPage3Pais} = useForm()
    const {page3TipoResidencia, setPage3TipoResidencia} = useForm()
    const {page3TipoEndereco, setPage3TipoEndereco} = useForm()    
    const {selectResidencia, setSelectResidencia} = useForm()

    const handleChangeEndereco = event => {
        setPage3TipoEndereco(event.target.value)
    };

    const handleChangeResidencia = event => {
        setSelectResidencia(event.target.value);
        setPage3TipoResidencia(event.target.value)
        setRegisterForm({tipoResidencia: event.target.value});
    };

    function isNewCorrentista() {
        setNewCorrentista(true)
    }

    function pageThreeToPageTwo() {
        setPageTwo(true)
        setPageThree(false)
    }

    function pageThreeToPageFour() {
        setPageThree(false)
        setPageFour(true)
    }

    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true
      })

      function consultaCep() {
        if (page3Cep.length === 9) {
          const URL = `/endereco/cep/${page3Cep.replace(/[^0-9]+/g, '')}`;
          api_multipague_cep
            .get(URL)
            .then(response => {
              setPage3Bairro(response.data.bairro);
              setPage3TipoLogradouro(response.data.nomeLogradouro);
              setPage3Logradouro(response.data.nomeLogradouro);
              setPage3Cidade(response.data.localidade.nome);
              setPage3Uf(response.data.siglaUf);
            })
            .catch(error => {
              console.log(error);
            });
        }
      }  

    return (
        <>
        {isWideVersion && (
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >
              <Box display="flex">
                <Stack direction='row'>
                  <Avatar name='1' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Heading as='h4' size='sm' my="2" ml="2">
                {checkTipoPessoa ? 'Dados da pessoa jurídica' : 'Dados da pessoa física'}
                </Heading>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='2' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de contato
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='3' size='sm' bg='#65c4d5' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de endereço
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='4' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Minha conta
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='5' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Termo
                </Text>
              </Box>
    
              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='6' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Senha
                </Text>
              </Box>
    
            </Flex>
          )}
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >        
              <Box alignItems='center'>
                <Heading as='h4' size='md' mb="0">
                    Dados de endereço
                </Heading>  
              </Box>
            </Flex>
            <Flex direction="column">          
    
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" border='1px' borderColor='gray.200' bg={bg} borderRadius={8}>            
    
                    <FormControl>
                      
                        <SimpleGrid columns={isWideVersion ? 2 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='cnpj'>CEP</FormLabel>
                                <Input 
                                    as={InputMask} 
                                    id='cnpj' 
                                    mask="99999-999" 
                                    borderColor='gray.300'
                                    value={page3Cep}
                                    onBlur={() => consultaCep()}
                                    onChange={(e) => setPage3Cep(e.target.value)} 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Logradouro</FormLabel>
                                <Input 
                                    id='razaoSocial'
                                    type='text'
                                    borderColor='gray.300'
                                    value={page3TipoLogradouro}
                                    onChange={(e) => setPage3TipoLogradouro(e.target.value)} 
                                />
                              </Box>  
                        </SimpleGrid>
                        <SimpleGrid columns={isWideVersion ? 3 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='cnpj'>Número</FormLabel>
                                <Input 
                                    id='cnpj' 
                                    borderColor='gray.300'
                                    value={page3Numero}
                                    onChange={(e) => setPage3Numero(e.target.value)} 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Complemento</FormLabel>
                                <Input 
                                    id='razaoSocial'
                                    type='text'
                                    borderColor='gray.300'
                                    value={page3Complemento}
                                    onChange={(e) => setPage3Complemento(e.target.value)} 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Bairro</FormLabel>
                                <Input 
                                    id='razaoSocial'
                                    type='text'
                                    borderColor='gray.300'
                                    value={page3Bairro}
                                    onChange={(e) => setPage3Bairro(e.target.value)} 
                                />
                              </Box>  
                        </SimpleGrid>
                        <SimpleGrid columns={isWideVersion ? 3 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='cnpj'>Cidade</FormLabel>
                                <Input 
                                    id='cnpj' 
                                    borderColor='gray.300'
                                    value={page3Cidade}
                                    onChange={(e) => setPage3Cidade(e.target.value)} 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>UF</FormLabel>
                                <Input 
                                    id='razaoSocial'
                                    type='text'
                                    borderColor='gray.300'
                                    value={page3Uf}
                                    onChange={(e) => setPage3Uf(e.target.value)} 
                                />
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>País</FormLabel>
                                <Input 
                                    id='razaoSocial'
                                    type='text'
                                    borderColor='gray.300'
                                    value={page3Pais}
                                    onChange={(e) => setPage3Pais(e.target.value)} 
                                />
                              </Box>  
                        </SimpleGrid>
                        <SimpleGrid columns={isWideVersion ? 2 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='cnpj'>Tipo de endereço</FormLabel>
                                <Select placeholder='Selecione uma opção' borderColor='gray.300' onChange={(event) => setPage3TipoEndereco(event.target.value)} value={page3TipoEndereco}>
                                    <option value='RESIDENCIAL'>Residencial</option>
                                    <option value='COMERCIAL'>Comercial</option>
                                </Select>
                              </Box>  
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                <FormLabel htmlFor='razaoSocial'>Tipo de residência</FormLabel>
                                <Select placeholder='Selecione uma opção' borderColor='gray.300' onChange={(event) => setPage3TipoResidencia(event.target.value)} value={page3TipoResidencia}>
                                    <option value='RESIDENCIA PROPRIA'>Própria</option>
                                    <option value='ALUGADA'>Alugada</option>
                                    <option value='FINANCIADA'>Financiada</option>
                                </Select>
                              </Box>   
                        </SimpleGrid>
                        
                    </FormControl>
                    
                </Flex> 
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6"  justifyContent="flex-end">
                        <Box>
                            <Button leftIcon={<ArrowBackIcon />} mr="5" colorScheme='teal' variant='outline' onClick={() => pageThreeToPageTwo()}>
                            Anterior
                            </Button>
                            <Button rightIcon={<ArrowForwardIcon />} colorScheme='teal' onClick={() => pageThreeToPageFour()}>
                            Próximo
                            </Button>
                        </Box>
                    </Flex>   
            </Flex>
        </>    
    )
}