import React, { createContext, useContext, useState } from 'react';

export const FormContext = createContext({});

export const FormProvider = props => {
  const [newCorrentista, setNewCorrentista] = useState([]);
  const [newCorrentistaContato, setNewCorrentistaContato] = useState([
    {
      ddi1: '',
      ddd1: '',
      telefone1: '',
      email1: null,
      ddi2: '',
      ddd2: '',
      telefone2: '',
      email2: null,
      ddi3: '',
      ddd3: '',
      telefone3: '',
      email3: null,
    },
  ]);
  const [contaSolidaria, setContaSolidaria] = useState();
  const [possuiConta, setPossuiConta] = useState(false);
  const [pageOne, setPageOne] = useState(true);
  const [pageTwo, setPageTwo] = useState(false);
  const [pageThree, setPageThree] = useState(false);
  const [pageFour, setPageFour] = useState(false);
  const [pageFive, setPageFive] = useState(false);
  const [pageSix, setPageSix] = useState(false);

  const [page1Cnpj, setPage1Cnpj] = useState();
  const [page1Pessoa, setPage1Pessoa] = useState();
  const [checkTipoPessoa, setCheckTipoPessoa] = useState(true);
  const [page1RazaoSocial, setPage1RazaoSocial] = useState();
  const [page1NomeFantasia, setPage1NomeFantasia] = useState();
  const [page1Cep, setPage1Cep] = useState();
  const [page1InscricaoEstadual, setPage1InscricaoEstadual] = useState();
  const [page1InscricaoMunicipal, setPage1InscricaoMunicipal] = useState();
  const [page1Bairro, setPage1Bairro] = useState();
  const [page1Logradouro, setPage1Logradouro] = useState();
  const [page1Complemento, setPage1Complemento] = useState();
  const [page1Numero, setPage1Numero] = useState();
  const [page1Municipio, setPage1Municipio] = useState();
  const [page1Uf, setPage1Uf] = useState();
  const [page1Abertura, setPage1Abertura] = useState();
  const [page1PaginaWeb, setPage1PaginaWeb] = useState();
  const [cpfPessoaFisica, setCpfPessoaFisica] = useState();
  const [page1Situacao, setPage1Situacao] = useState();
  const [page1RepresentanteCpf, setPage1RepresentanteCpf] = useState();
  const [page1RepresentanteRg, setPage1RepresentanteRg] = useState();
  const [page1RepresentanteNome, setPage1RepresentanteNome] = useState();
  const [page1Representante2Cpf, setPage1Representante2Cpf] = useState();
  const [page1Representante2Rg, setPage1Representante2Rg] = useState();
  const [page1Representante2Nome, setPage1Representante2Nome] = useState();
  const [page1TipoDePessoa, setPage1TipoDePessoa] = useState('J');

  const [page2RepresentanteEmail, setPage2RepresentanteEmail] = useState();
  const [page2RepresentanteRedeSocial, setPage2RepresentanteRedeSocial] =
    useState();
  const [page2RepresentanteDDD, setPage2RepresentanteDDD] = useState();
  const [page2RepresentanteDDI, setPage2RepresentanteDDI] = useState();
  const [page2Representante2DDI, setPage2Representante2DDI] = useState();
  const [page2Representante2DDI2, setPage2Representante2DDI2] = useState();
  const [page2Representante2DDI3, setPage2Representante2DDI3] = useState();
  const [page2RepresentanteDDI2, setPage2RepresentanteDDI2] = useState();
  const [page2RepresentanteDDI3, setPage2RepresentanteDDI3] = useState();
  const [page2RepresentanteDDD2, setPage2RepresentanteDDD2] = useState();
  const [page2RepresentanteDDD3, setPage2RepresentanteDDD3] = useState();
  const [page2Representante2DDD, setPage2Representante2DDD] = useState();
  const [page2Representante2DDD2, setPage2Representante2DDD2] = useState();
  const [page2Representante2DDD3, setPage2Representante2DDD3] = useState();
  const [page2RepresentanteTelefone, setPage2RepresentanteTelefone] =
    useState();
  const [page2RepresentanteTelefone2, setPage2RepresentanteTelefone2] =
    useState();
  const [page2RepresentanteTelefone3, setPage2RepresentanteTelefone3] =
    useState();
  const [page2Representante2Email, setPage2Representante2Email] = useState();
  const [page2Representante2RedeSocial, setPage2Representante2RedeSocial] =
    useState();
  const [page2Representante2Telefone, setPage2Representante2Telefone] =
    useState();
  const [page2Representante2Telefone2, setPage2Representante2Telefone2] =
    useState();
  const [page2Representante2Telefone3, setPage2Representante2Telefone3] =
    useState();

  const [page3Cep, setPage3Cep] = useState('');
  const [page3Bairro, setPage3Bairro] = useState('');
  const [page3TipoLogradouro, setPage3TipoLogradouro] = useState('');
  const [page3Logradouro, setPage3Logradouro] = useState('');
  const [page3Complemento, setPage3Complemento] = useState('');
  const [page3Numero, setPage3Numero] = useState('');
  const [page3Cidade, setPage3Cidade] = useState('');
  const [page3Uf, setPage3Uf] = useState('');
  const [page3Pais, setPage3Pais] = useState('Brasil');
  const [page3TipoResidencia, setPage3TipoResidencia] = useState('');
  const [page3TipoEndereco, setPage3TipoEndereco] = useState('');
  const [aceito, setAceito] = useState();
  const [page6Senha, setPage6Senha] = useState('');
  const [page6ConfirmaSenha, setPage6ConfirmaSenha] = useState('');
  const [page6SenhaFinanceira, setPage6SenhaFinanceira] = useState('');
  const [page6ConfirmaSenhaFinanceira, setPage6ConfirmaSenhaFinanceira] =
    useState('');
  const [displayButton, setDisplayButton] = useState('');

  const [registerForm, setRegisterForm] = useState();

  return (
    <FormContext.Provider
      value={{
        registerForm,
        setRegisterForm,
        newCorrentista,
        setNewCorrentista,
        contaSolidaria,
        setContaSolidaria,
        possuiConta,
        setPossuiConta,
        pageOne,
        setPageOne,
        pageTwo,
        setPageTwo,
        pageThree,
        setPageThree,
        pageFour,
        setPageFour,
        pageFive,
        setPageFive,
        pageSix,
        setPageSix,
        checkTipoPessoa,
        setCheckTipoPessoa,
        page1TipoDePessoa,
        setPage1TipoDePessoa,
        page1Cnpj,
        setPage1Cnpj,
        page1Pessoa,
        setPage1Pessoa,
        page1RazaoSocial,
        setPage1RazaoSocial,
        page1NomeFantasia,
        setPage1NomeFantasia,
        page1InscricaoEstadual,
        setPage1InscricaoEstadual,
        page1InscricaoMunicipal,
        setPage1InscricaoMunicipal,
        page1Cep,
        setPage1Cep,
        page1Bairro,
        setPage1Bairro,
        page1Logradouro,
        setPage1Logradouro,
        page1Complemento,
        setPage1Complemento,
        page1Numero,
        setPage1Numero,
        page1Municipio,
        setPage1Municipio,
        page1Uf,
        setPage1Uf,
        page1Abertura,
        setPage1Abertura,
        page1PaginaWeb,
        setPage1PaginaWeb,
        cpfPessoaFisica,
        setCpfPessoaFisica,
        page1Situacao,
        setPage1Situacao,
        page1RepresentanteCpf,
        setPage1RepresentanteCpf,
        page1RepresentanteRg,
        setPage1RepresentanteRg,
        page1RepresentanteNome,
        setPage1RepresentanteNome,
        page1Representante2Cpf,
        setPage1Representante2Cpf,
        page1Representante2Rg,
        setPage1Representante2Rg,
        page1Representante2Nome,
        setPage1Representante2Nome,
        page2RepresentanteEmail,
        setPage2RepresentanteEmail,
        page2RepresentanteRedeSocial,
        setPage2RepresentanteRedeSocial,
        page2Representante2Email,
        setPage2Representante2Email,
        page2Representante2RedeSocial,
        setPage2Representante2RedeSocial,
        page2RepresentanteDDD,
        setPage2RepresentanteDDD,
        page2RepresentanteDDI,
        setPage2RepresentanteDDI,
        page2RepresentanteDDI2,
        setPage2RepresentanteDDI2,
        page2RepresentanteDDI3,
        setPage2RepresentanteDDI3,
        page2RepresentanteDDD2,
        setPage2RepresentanteDDD2,
        page2RepresentanteDDD3,
        setPage2RepresentanteDDD3,
        page2Representante2DDD,
        setPage2Representante2DDD,
        page2Representante2DDD2,
        setPage2Representante2DDD2,
        page2Representante2DDD3,
        setPage2Representante2DDD3,
        page2RepresentanteTelefone,
        setPage2RepresentanteTelefone,
        page2RepresentanteTelefone2,
        setPage2RepresentanteTelefone2,
        page2RepresentanteTelefone3,
        setPage2RepresentanteTelefone3,
        page2Representante2Telefone,
        setPage2Representante2Telefone,
        page2Representante2Telefone2,
        setPage2Representante2Telefone2,
        page2Representante2Telefone3,
        setPage2Representante2Telefone3,
        page3Cep,
        setPage3Cep,
        page3Logradouro,
        setPage3Logradouro,
        page3TipoLogradouro,
        setPage3TipoLogradouro,
        page3Numero,
        setPage3Numero,
        page3Complemento,
        setPage3Complemento,
        page3Bairro,
        setPage3Bairro,
        page3Cidade,
        setPage3Cidade,
        page3Uf,
        setPage3Uf,
        page3Pais,
        setPage3Pais,
        page3TipoEndereco,
        setPage3TipoEndereco,
        page3TipoResidencia,
        setPage3TipoResidencia,
        aceito,
        setAceito,
        page6Senha,
        setPage6Senha,
        page6ConfirmaSenha,
        setPage6ConfirmaSenha,
        page6SenhaFinanceira,
        setPage6SenhaFinanceira,
        page6ConfirmaSenhaFinanceira,
        page2Representante2DDI,
        setPage2Representante2DDI,
        page2Representante2DDI2,
        setPage2Representante2DDI2,
        page2Representante2DDI3,
        setPage2Representante2DDI3,
        setPage6ConfirmaSenhaFinanceira,
        displayButton,
        setDisplayButton,
        newCorrentistaContato,
        setNewCorrentistaContato,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);
