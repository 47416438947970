import React from 'react'

import { ArrowBackIcon, CheckIcon, Icon } from '@chakra-ui/icons'

import {
  useBreakpointValue,
  Flex,
  SimpleGrid,
  Box,
  Text,
  Button,
  UnorderedList,
  ListItem,
  useColorModeValue
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core'

export default function Pagina5() {

    const bg = useColorModeValue('#fff', 'gray.800')

    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true
      })

    return (
        <>
            <Flex direction="column">
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" border='1px' borderColor='gray.200' bg={bg} borderRadius={8}>
                        <SimpleGrid columns={isWideVersion ? 1 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                                  textAlign="center"
                              >
                                  <Icon as={CheckIcon} w={32} h={32} color='blue.500' />
                                  <Text fontSize='26px' color='tomato'>
                                    Parabéns, seu processo de abertura de conta está quase finalizado.
                                  </Text>
                              </Box>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                  <Text fontSize='18px'>
                                    Precisamos que envie os documentos abaixo:
                                  </Text>
                                  <UnorderedList>
                                    <ListItem fontSize='18px'>Procurações (se houver)</ListItem>
                                    <ListItem fontSize='18px'>RG e CPF ou CNH</ListItem>
                                    <ListItem fontSize='18px'>Comprovante de endereço (conta de consumo dos últimos 90 dias) dos sócios e representantes legais</ListItem>
                                    <ListItem fontSize='18px'>Contato (E-mail – telefone)</ListItem>
                                  </UnorderedList>
                                  <Text fontSize='18px'>
                                    Para o e-mail beyondbank@grupomultiplica.com.br
                                    Após a análise dos documentos acima, você receberá um e-mail com seus dados bancários e validação da sua conta{" "}{useTheme()?.config?.clienteName}.
                                  </Text>
                              </Box>
                        </SimpleGrid>
                </Flex>
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6"  justifyContent="flex-end">
                    <Box>
                    <Link to="/login">
                        <Button leftIcon={<ArrowBackIcon />} mr="5" colorScheme='teal' variant='outline'>
                            Voltar para Login
                        </Button>
                    </Link>
                    </Box>
                </Flex>
            </Flex>
        </>
    )
}
