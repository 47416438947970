import React, { useState } from 'react';

import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useForm } from '../../contexts/FormContext';
import { api_multipague_conta } from '../../services/api';
import Pagina7 from '../Pagina7';

export default function Pagina6({ theme }) {
  const toast = useToast();
  const bg = useColorModeValue('#fff', 'gray.800');
  const topNumer = useColorModeValue('#fff', 'gray.800');

  const [abriuConta, setAbriuConta] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const { checkTipoPessoa } = useForm();
  const { contaSolidaria } = useForm();
  const { page1Cnpj } = useForm();
  const { page1RazaoSocial } = useForm();
  const { page1NomeFantasia } = useForm();
  const { page1InscricaoEstadual } = useForm();
  const { page1InscricaoMunicipal } = useForm();
  const { page1Abertura } = useForm();
  const { page1PaginaWeb } = useForm();
  const { page1RepresentanteCpf } = useForm();
  const { page1RepresentanteRg } = useForm();
  const { page1RepresentanteNome } = useForm();
  const { page1Representante2Cpf } = useForm();
  const { page1Representante2Rg } = useForm();
  const { page1Representante2Nome } = useForm();
  const { page1TipoDePessoa } = useForm();
  const { page2RepresentanteEmail } = useForm();
  const { page2RepresentanteRedeSocial } = useForm();
  const {
    page2RepresentanteDDD,
    page2RepresentanteDDI,
    page2RepresentanteDDI2,
    page2RepresentanteDDI3,
    page2Representante2DDD3,
    page2Representante2DDI,
    page2Representante2DDI2,
    page2Representante2DDI3,
  } = useForm();
  const { page2RepresentanteDDD2 } = useForm();
  const { page2RepresentanteDDD3 } = useForm();
  const { page2Representante2DDD } = useForm();
  const { page2Representante2DDD2 } = useForm();
  const { page2RepresentanteTelefone } = useForm();
  const { page2RepresentanteTelefone2 } = useForm();
  const { page2RepresentanteTelefone3 } = useForm();
  const { page2Representante2Email } = useForm();
  const { page2Representante2RedeSocial } = useForm();
  const { page2Representante2Telefone } = useForm();
  const { page2Representante2Telefone2 } = useForm();
  const { page2Representante2Telefone3 } = useForm();
  const { page3Cep } = useForm();
  const { page3Bairro } = useForm();
  const { page3TipoLogradouro } = useForm();
  const { page3Complemento } = useForm();
  const { page3Numero } = useForm();
  const { page3Cidade } = useForm();
  const { page3Uf } = useForm();
  const { page3Pais } = useForm();
  const { page3TipoResidencia } = useForm();
  const { page3TipoEndereco } = useForm();
  const { setPageFive } = useForm();
  const { setPageSix } = useForm();
  const { newCorrentista } = useForm();

  function pageSixToPageFive() {
    setPageFive(true);
    setPageSix(false);
  }

  const formData = {
    pessoaJuridica: {
      cnpj: page1Cnpj,
      razaoSocial: page1RazaoSocial,
      nomeFantasia: page1NomeFantasia,
      inscricaoEstadual: page1InscricaoEstadual,
      inscricaoMunicipal: page1InscricaoMunicipal,
      abertura:
        page1Abertura != null
          ? new Intl.DateTimeFormat('pt-BR').format(new Date(page1Abertura))
          : new Intl.DateTimeFormat('pt-BR').format(new Date()),
      paginaWeb: page1PaginaWeb,
    },
    correntistasResponsavel: [
      {
        cpf: page1RepresentanteCpf,
        rg: page1RepresentanteRg,
        nome: page1RepresentanteNome,
        representanteLegal: true,
        contato: {
          nome: page1RepresentanteNome,
          email: page2RepresentanteEmail,
          redeSocial:
            page2RepresentanteRedeSocial === ''
              ? 'Nenhuma'
              : page2RepresentanteRedeSocial,
          telefones: [
            {
              ddi: page2RepresentanteDDI,
              ddd: page2RepresentanteDDD,
              telefone: page2RepresentanteTelefone,
            },
            {
              ddi: page2RepresentanteDDI2,
              ddd: page2RepresentanteDDD2,
              telefone: page2RepresentanteTelefone2,
            },
            {
              ddi: page2RepresentanteDDI3,
              ddd: page2RepresentanteDDD3,
              telefone: page2RepresentanteTelefone3,
            },
          ],
        },
      },
    ],
    endereco: {
      cep: page3Cep,
      endereco: page3TipoLogradouro,
      enderecoNum: page3Numero,
      complemento: page3Complemento,
      bairro: page3Bairro,
      cidade: page3Cidade,
      estado: page3Uf,
      pais: page3Pais,
      tipoEndereco: page3TipoEndereco,
      tipoResidencia: page3TipoResidencia,
    },
    tipoPessoa: page1TipoDePessoa,
    aceiteTermo: 1,
    nrBanco: 274,
    agenciaPadrao: 1,
    tipoConta: 3,
    descricao: 'CONTA PAGAMENTO',
    assinaturaSolidaria: contaSolidaria,
  };
  const formDataDupla = {
    pessoaJuridica: {
      cnpj: page1Cnpj,
      razaoSocial: page1RazaoSocial,
      nomeFantasia: page1NomeFantasia,
      inscricaoEstadual: page1InscricaoEstadual,
      inscricaoMunicipal: page1InscricaoMunicipal,
      abertura:
        page1Abertura != null
          ? new Intl.DateTimeFormat('pt-BR').format(new Date(page1Abertura))
          : new Intl.DateTimeFormat('pt-BR').format(new Date()),
      paginaWeb: page1PaginaWeb,
    },
    correntistasResponsavel: [
      {
        cpf: page1RepresentanteCpf,
        rg: page1RepresentanteRg,
        nome: page1RepresentanteNome,
        representanteLegal: newCorrentista ? true : false,
        contato: {
          nome: page1RepresentanteNome,
          email: page2RepresentanteEmail,
          redeSocial:
            page2RepresentanteRedeSocial === ''
              ? 'Nenhuma'
              : page2RepresentanteRedeSocial,
          telefones: [
            {
              ddi: page2RepresentanteDDI,
              ddd: page2RepresentanteDDD,
              telefone: page2RepresentanteTelefone,
            },
            {
              ddi: page2RepresentanteDDI2,
              ddd: page2RepresentanteDDD2,
              telefone: page2RepresentanteTelefone2,
            },
            {
              ddi: page2RepresentanteDDI3,
              ddd: page2RepresentanteDDD3,
              telefone: page2RepresentanteTelefone3,
            },
          ],
        },
      },
      ...newCorrentista.map(item => ({
        cpf: item.cpf,
        rg: item.rg,
        nome: item.nome,
        representanteLegal: item.contaSolidaria,
        contato: {
          nome: item.nome,
          email: item.email,
          redeSocial: item.redeSocial === '' ? 'Nenhuma' : item.redeSocial,
          telefones: [
            {
              ddi: item.ddi1,
              ddd: item.ddd1,
              telefone: item.telefone1,
            },
            {
              ddi: item.ddi2,
              ddd: item.ddd2,
              telefone: item.telefone2,
            },
            {
              ddi: item.ddi3,
              ddd: item.ddd3,
              telefone: item.telefone3,
            },
          ],
        },
      })),
    ],
    endereco: {
      cep: page3Cep,
      endereco: page3TipoLogradouro,
      enderecoNum: page3Numero,
      complemento: page3Complemento,
      bairro: page3Bairro,
      cidade: page3Cidade,
      estado: page3Uf,
      pais: page3Pais,
      tipoEndereco: page3TipoEndereco,
      tipoResidencia: page3TipoResidencia,
    },
    tipoPessoa: page1TipoDePessoa,
    aceiteTermo: 1,
    nrBanco: 274,
    agenciaPadrao: 1,
    tipoConta: 3,
    descricao: 'CONTA PAGAMENTO',
    assinaturaSolidaria: contaSolidaria,
  };

  const formDataF = {
    pessoaFisica: {
      nome: page1RepresentanteNome,
      rg: page1RepresentanteRg,
      cpf: page1RepresentanteCpf,
    },
    correntistasResponsavel: [
      {
        cpf: page1RepresentanteCpf,
        rg: page1RepresentanteRg,
        nome: page1RepresentanteNome,
        representanteLegal: true,
        contato: {
          nome: page1RepresentanteNome,
          email: page2RepresentanteEmail,
          redeSocial:
            page2RepresentanteRedeSocial === ''
              ? 'Nenhuma'
              : page2RepresentanteRedeSocial,
          telefones: [
            {
              ddi: page2RepresentanteDDI,
              ddd: page2RepresentanteDDD,
              telefone: page2RepresentanteTelefone,
            },
            {
              ddi: page2RepresentanteDDI2,
              ddd: page2RepresentanteDDD2,
              telefone: page2RepresentanteTelefone2,
            },
            {
              ddi: page2RepresentanteDDI3,
              ddd: page2RepresentanteDDD3,
              telefone: page2RepresentanteTelefone3,
            },
          ],
        },
      },
    ],
    endereco: {
      cep: page3Cep,
      endereco: page3TipoLogradouro,
      enderecoNum: page3Numero,
      complemento: page3Complemento,
      bairro: page3Bairro,
      cidade: page3Cidade,
      estado: page3Uf,
      pais: page3Pais,
      tipoEndereco: page3TipoEndereco,
      tipoResidencia: page3TipoResidencia,
    },
    tipoPessoa: page1TipoDePessoa,
    aceiteTermo: 1,
    nrBanco: 274,
    agenciaPadrao: 1,
    tipoConta: 3,
    descricao: 'CONTA PAGAMENTO',
    assinaturaSolidaria: contaSolidaria,
  };
  const formDataFDupla = {
    pessoaFisica: {
      nome: page1RepresentanteNome,
      rg: page1RepresentanteRg,
      cpf: page1RepresentanteCpf,
    },
    correntistasResponsavel: [
      {
        cpf: page1RepresentanteCpf,
        rg: page1RepresentanteRg,
        nome: page1RepresentanteNome,
        representanteLegal: newCorrentista ? true : false,
        contato: {
          nome: page1RepresentanteNome,
          email: page2RepresentanteEmail,
          redeSocial:
            page2RepresentanteRedeSocial === ''
              ? 'Nenhuma'
              : page2RepresentanteRedeSocial,
          telefones: [
            {
              ddi: page2RepresentanteDDI,
              ddd: page2RepresentanteDDD,
              telefone: page2RepresentanteTelefone,
            },
            {
              ddi: page2RepresentanteDDI2,
              ddd: page2RepresentanteDDD2,
              telefone: page2RepresentanteTelefone2,
            },
            {
              ddi: page2RepresentanteDDI3,
              ddd: page2RepresentanteDDD3,
              telefone: page2RepresentanteTelefone3,
            },
          ],
        },
      },
      ...newCorrentista.map(item => ({
        cpf: item.cpf,
        rg: item.rg,
        nome: item.nome,
        representanteLegal: item.contaSolidaria,
        contato: {
          nome: item.nome,
          email: item.email,
          redeSocial: item.redeSocial === '' ? 'Nenhuma' : item.redeSocial,
          telefones: [
            {
              ddi: item.ddi1,
              ddd: item.ddd1,
              telefone: item.telefone1,
            },
            {
              ddi: item.ddi2,
              ddd: item.ddd2,
              telefone: item.telefone2,
            },
            {
              ddi: item.ddi3,
              ddd: item.ddd3,
              telefone: item.telefone3,
            },
          ],
        },
      })),
    ],
    endereco: {
      cep: page3Cep,
      emdereco: page3TipoLogradouro,
      enderecoNum: page3Numero,
      complemento: page3Complemento,
      bairro: page3Bairro,
      cidade: page3Cidade,
      estado: page3Uf,
      pais: page3Pais,
      tipoEndereco: page3TipoEndereco,
      tipoResidencia: page3TipoResidencia,
    },
    tipoPessoa: page1TipoDePessoa,
    aceiteTermo: 1,
    nrBanco: 274,
    agenciaPadrao: 1,
    tipoConta: 3,
    descricao: 'CONTA PAGAMENTO',
    assinaturaSolidaria: contaSolidaria,
  };

  function finalizar() {
    setEnviando(true);
    api_multipague_conta
      .post(
        `/aberturaConta`,
        newCorrentista && page1TipoDePessoa == 'J'
          ? JSON.stringify(formDataDupla)
          : !newCorrentista && page1TipoDePessoa == 'J'
          ? JSON.stringify(formData)
          : newCorrentista && page1TipoDePessoa == 'F'
          ? JSON.stringify(formDataFDupla)
          : JSON.stringify(formDataF),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(function (response) {
        setEnviando(false);
        toast({
          title: 'Conta criada!',
          description: `Bem-vindo ao ${
            theme ? theme?.config?.clienteName : 'Beyond Bank'
          }`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setAbriuConta(true);
      })
      .catch(function (error) {
        console.log(error);
        setEnviando(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          error.response.data.listErrors
            ? error.response.data.listErrors.map(usr => {
                return toast({
                  title: 'Ops!',
                  description: usr,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              })
            : toast({
                title: 'Ops!',
                description: error.response.data.mensagem,
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
        }
      });
  }

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <>
      {abriuConta && <Pagina7 />}
      {isWideVersion && !abriuConta && (
        <>
          <Flex
            w="100%"
            my="6"
            maxWidth={1120}
            mx="auto"
            px="6"
            justifyContent="space-between"
          >
            <Box display="flex">
              <Stack direction="row">
                <Avatar name="1" size="sm" bg="gray.300" color={topNumer} />
              </Stack>
              <Heading as="h4" size="sm" my="2" ml="2">
                {checkTipoPessoa
                  ? 'Dados da pessoa jurídica'
                  : 'Dados da pessoa física'}
              </Heading>
            </Box>

            <Box display="flex">
              <Stack direction="row" ml={5}>
                <Avatar name="2" size="sm" bg="gray.300" color={topNumer} />
              </Stack>
              <Text fontSize="sm" my="2" ml="2">
                Dados de contato
              </Text>
            </Box>

            <Box display="flex">
              <Stack direction="row" ml={5}>
                <Avatar name="3" size="sm" bg="gray.300" color={topNumer} />
              </Stack>
              <Text fontSize="sm" my="2" ml="2">
                Dados de endereço
              </Text>
            </Box>

            <Box display="flex">
              <Stack direction="row" ml={5}>
                <Avatar name="4" size="sm" bg="gray.300" color={topNumer} />
              </Stack>
              <Text fontSize="sm" my="2" ml="2">
                Minha conta
              </Text>
            </Box>

            <Box display="flex">
              <Stack direction="row" ml={5}>
                <Avatar name="5" size="sm" bg="gray.300" color={topNumer} />
              </Stack>
              <Text fontSize="sm" my="2" ml="2">
                Termo
              </Text>
            </Box>

            <Box display="flex">
              <Stack direction="row" ml={5}>
                <Avatar name="6" size="sm" bg="#65c4d5" color={topNumer} />
              </Stack>
              <Text fontSize="sm" my="2" ml="2">
                Senha
              </Text>
            </Box>
          </Flex>

          <Flex
            w="100%"
            my="6"
            maxWidth={1120}
            mx="auto"
            px="6"
            justifyContent="space-between"
          >
            <Box alignItems="center">
              <Heading as="h4" size="md" mb="0">
                Confira os emails que receberão as instruções de cadastro de
                senhas.
              </Heading>
            </Box>
          </Flex>
          <Flex direction="column">
            <Flex
              w="100%"
              my="6"
              maxWidth={1120}
              mx="auto"
              px="6"
              border="1px"
              borderColor="gray.200"
              bg={bg}
              borderRadius={8}
            >
              <FormControl>
                <SimpleGrid columns={isWideVersion ? 2 : 1} spacing={2} bg={bg}>
                  <Box p={['3', '3']} borderRadius={8} pb="4">
                    <FormLabel htmlFor="primeirousuario">Usuário</FormLabel>
                    <Input
                      id="primeirousuario"
                      type="email"
                      borderColor="gray.300"
                      value={page2RepresentanteEmail}
                      disabled
                    />
                  </Box>
                  {newCorrentista && (
                    <Box p={['3', '3']} borderRadius={8} pb="4">
                      <FormLabel htmlFor="segundousuario">
                        Segundo usuário
                      </FormLabel>
                      <Input
                        id="segundousuario"
                        type="email"
                        borderColor="gray.300"
                        value={page2Representante2Email}
                        disabled
                      />
                    </Box>
                  )}
                </SimpleGrid>
              </FormControl>
            </Flex>
            <Flex
              w="100%"
              my="6"
              maxWidth={1120}
              mx="auto"
              px="6"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  mr="5"
                  colorScheme="#394C59"
                  variant="outline"
                  onClick={() => pageSixToPageFive()}
                >
                  Anterior
                </Button>
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  colorScheme="teal"
                  isLoading={enviando}
                  loadingText="Enviando"
                  onClick={() => finalizar()}
                >
                  Finalizar
                </Button>
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
}
